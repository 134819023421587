import { SeoComponentProps, SeoObject, Meta, QueryResult } from './types'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        fbAppId
        defaultImage
      }
    }
  }
`

const fallbackSiteUrl = 'https://bensoleimani.com/'

const hasTitleApex = (defaultTitle: string, title?: string): boolean =>
  String(title).indexOf(defaultTitle) >= 0 ||
  String(title).indexOf(defaultTitle.toLocaleUpperCase('en')) >= 0

const SEO = ({ title, description, image = null, article, lang = 'en' }: SeoComponentProps) => {
  const { pathname, host } = useLocation()
  const { site } = useStaticQuery<QueryResult>(query)
  const { defaultTitle, defaultDescription, siteUrl, fbAppId, defaultImage } = site.siteMetadata

  const parseDescription = (description: string) => {
    const maxLength = 150
    // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
    const cleanDescription = description.replace(/(<([^>]+)>)/gi, '')
    const parsedDescription =
      cleanDescription.length > maxLength
        ? `${cleanDescription.slice(0, maxLength)}...`
        : cleanDescription

    return parsedDescription
  }

  const getURL = () => {
    if (siteUrl.includes('localhost') || host?.includes('localhost')) {
      return fallbackSiteUrl
    } else {
      return siteUrl || host
    }
  }

  const cleanTitle = (contentTitle: string) => {
    return contentTitle.replace(/<[^>]*>/g, '')
  }

  const seo: SeoObject = {
    title: title
      ? hasTitleApex(defaultTitle, title)
        ? cleanTitle(title)
        : `${cleanTitle(title)} | ${defaultTitle}`
      : defaultTitle,
    description: parseDescription(description || defaultDescription),
    url: `${getURL()}${pathname}`,
    image: image || defaultImage,
  }

  const meta: Meta[] = [
    {
      property: `og:site_name`,
      content: defaultTitle,
    },
    {
      name: `description`,
      content: seo.description,
    },
    {
      property: `og:title`,
      content: seo.title,
    },
    {
      property: `og:description`,
      content: seo.description,
    },
    {
      property: `og:type`,
      content: article ? `article` : `website`,
    },
    seo.url && {
      name: 'og:url',
      content: seo.url,
    },
    fbAppId && {
      property: 'fb:app_id',
      content: fbAppId,
    },
    seo.image && {
      property: `og:image`,
      content: seo.image,
    },
  ].filter(Boolean) as Meta[]

  const links = [
    {
      rel: 'canonical',
      href: seo.url,
    },
  ]

  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang,
      }}
      link={links}
      meta={meta}
    />
  )
}

export default SEO
