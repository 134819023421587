import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.bespoke.primary};
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  #klaviyoNewsletter {
    button {
      padding: 12px 24px;
      background: #2d2d2d;
      font-family: var(--font-family-sans-serif);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 1px;
      transition: background-color 0.3s ease;

      &:hover {
        background: #000;
      }
    }
  }

  #rc-anchor-container {
    background-color: transparent !important;
  }
`

export const InquireLink = styled.a`
  color: #000;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 2px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`

export const EmailSection = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;

  h3 {
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  form {
    display: flex;
    gap: 8px;
  }
`

export const EmailInput = styled.input`
  flex: 1;
  padding: 12px 16px;
  border: none;
  background: white;
  font-size: 14px;

  &::placeholder {
    color: #666;
  }
`

export const SubmitButton = styled.button`
  padding: 12px 24px;
  background: #2d2d2d;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;

  &:hover {
    background: #000;
  }
`

export const Copyright = styled.div`
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
`
