import styled from 'styled-components'
import { Link } from 'gatsby'

export const HeaderWrapper = styled.header`
  background-color: ${({ theme }) => theme.bespoke.red};
  width: 100%;
  padding: 12px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  color: #fff;
`

export const HeaderContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LookbookLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: opacity 0.3s ease;
  text-align: center;
  flex: 1;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    padding-bottom: 4px;
  }
`

export const AccountButton = styled(Link)`
  color: currentColor;

  font-family: var(--font-family-sans-serif);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 1px;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: 8px;
  text-underline-position: from-font;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    text-decoration-line: underline;
  }

  .bespoke-account-name {
    display: block;
  }

  .bespoke-account-icon {
    display: none;
  }

  @media (max-width: 768px) {
    .bespoke-account-name {
      display: none;
    }

    .bespoke-account-icon {
      display: block;
      fill: currentColor;
    }

    strong {
      font-size: 8px;
    }
  }
`
