/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import KlaviyoForm from '~/utils/klaviyoNewsletterForm'

type Props = {}

const NewsletterFooter: FC<Props> = () => {
  const klaviyoFooterFormId = process.env.GATSBY_KLAVIYO_FOOTER_NEWSLETTER_FORM_ID || ''

  return <KlaviyoForm formId={klaviyoFooterFormId} loading="Loading..." />
}

export default NewsletterFooter
