/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { LinkType } from '~/@types/models'
import Accordion from '../../accordion'

type Props = {
  name: string
  links: readonly LinkType[]
  sxProps?: object
}

const FooterLinksCategory: FC<Props> = ({ name, links, sxProps = {} }) => {
  const transformedData = [
    {
      label: name,
      content: links,
    },
  ]

  return (
    <Accordion
      enableMarkdownContent
      data={transformedData as any}
      variant="bsol-footer"
      sx={{
        textAlign: ['center', 'start'],
        fontSize: [18, null, 18],
        fontWeight: '700',
        letterSpacing: '0.5px',
      }}
    />
  )
}

export default FooterLinksCategory
