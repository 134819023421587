import React from 'react'
import { Link } from 'gatsby'
import { HeaderWrapper, HeaderContent, LookbookLink, AccountButton } from './styles'
import BespokeAccount from './bespoke-account'
import { InquireForm } from './inquire-form'

export const BespokeHeader = () => {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <AccountButton to="/">
          Back to <strong>BENSOLEIMANI.COM</strong>
        </AccountButton>
        <div
          className="bespoke-header-center-link"
          style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center' }}
        >
          <LookbookLink to="/bespoke-lookbook">Explore our Custom Studio Lookbook</LookbookLink>
        </div>
        <BespokeAccount />
      </HeaderContent>
      <InquireForm />
    </HeaderWrapper>
  )
}
