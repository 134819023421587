import React, { useState } from 'react'
import {
  FooterWrapper,
  InquireLink,
  EmailSection,
  EmailInput,
  SubmitButton,
  Copyright,
} from './styles'
import NewsletterFooter from './newsletter'
export const BespokeFooter = () => {
  return (
    <FooterWrapper>
      <InquireLink href="/inquire">INQUIRE</InquireLink>

      <EmailSection>
        <h3>SIGN UP FOR EMAILS</h3>
        <NewsletterFooter />
      </EmailSection>

      <Copyright>© COPYRIGHT 2025 BESPOKE BY BEN SOLEIMANI</Copyright>
    </FooterWrapper>
  )
}
