import React, { useCallback } from 'react'
import { useTheme } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

export interface IMedia {
  id: string
  placeholder: string
  url: string
  width: number
  height: number
  alternativeText: string
  caption: string
  formats: {
    [key: string]: {
      url: string
      width: number
      height: number
    }
  }
}

export interface ResposiveImageProps extends React.HTMLProps<HTMLImageElement> {
  desktop?: IMedia
  mobile?: IMedia
  tablet?: IMedia
}

const DesktopImages = ({ image }: { image?: IMedia }) => {
  const theme = useTheme()

  if (!image?.url) {
    return null
  }

  return (
    <>
      {image.formats.xlarge && (
        <source
          data-query="xlarge"
          data-originalsrc={image.formats.xlarge.url}
          media={theme.mediaQueries.xl}
          srcSet={image.placeholder || image.formats.xlarge.url}
        />
      )}
      {image.formats.xxlarge && (
        <source
          data-query="xxlarge"
          data-originalsrc={image.formats.xxlarge.url}
          media={theme.mediaQueries.xxl}
          srcSet={image.placeholder || image.formats.xxlarge.url}
        />
      )}
    </>
  )
}

const MobileImages = ({ image }: { image?: IMedia }) => {
  const theme = useTheme()

  if (!image?.url) {
    return null
  }

  return (
    <>
      {image.formats.small && (
        <source
          data-query="small"
          data-originalsrc={image.formats.small.url}
          media={theme.mediaQueries.sm}
          srcSet={image.placeholder || image.formats.small.url}
        />
      )}
    </>
  )
}
const TabletImages = ({ image }: { image?: IMedia }) => {
  const theme = useTheme()

  if (!image?.url) {
    return null
  }

  return (
    <>
      {image.formats.medium && (
        <source
          data-query="medium"
          data-originalsrc={image.formats.medium.url}
          media={theme.mediaQueries.md}
          srcSet={image.placeholder || image.formats.medium.url}
        />
      )}
      {image.formats.large && (
        <source
          data-query="large"
          data-originalsrc={image.formats.large.url}
          media={theme.mediaQueries.lg}
          srcSet={image.placeholder || image.formats.large.url}
        />
      )}
    </>
  )
}

const ResposiveImage: React.FC<ResposiveImageProps> = ({
  desktop,
  mobile,
  tablet,
  children,
  ...props
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery({ query: theme.mediaQueries.sm })
  const isMedium = useMediaQuery({ query: theme.mediaQueries.md })
  const isLarge = useMediaQuery({ query: theme.mediaQueries.lg })
  const isXLarge = useMediaQuery({ query: theme.mediaQueries.xl })
  const isXXLarge = useMediaQuery({ query: theme.mediaQueries.xxl })

  const handleOnLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const img = e.target as HTMLImageElement
      const piture = img.parentElement as HTMLPictureElement
      const src = img.currentSrc
      if (src.indexOf('data:image') === -1) {
        return
      }

      let source: HTMLSourceElement | null = null

      if (isSmall) {
        source = piture.querySelector('source[data-query="small"]') as HTMLSourceElement
      } else if (isMedium) {
        source = piture.querySelector('source[data-query="medium"]') as HTMLSourceElement
      } else if (isLarge) {
        source = piture.querySelector('source[data-query="large"]') as HTMLSourceElement
      } else if (isXLarge) {
        source = piture.querySelector('source[data-query="xlarge"]') as HTMLSourceElement
      } else if (isXXLarge) {
        source = piture.querySelector('source[data-query="xxlarge"]') as HTMLSourceElement
      }

      if (!source) {
        source = piture.querySelector('source[data-query="default"]') as HTMLSourceElement
      }

      const originalSrc = source.getAttribute('data-originalsrc')

      if (!originalSrc) {
        return
      }

      const imgOriginal = new Image()

      imgOriginal.src = originalSrc
      imgOriginal.onload = () => {
        source?.setAttribute('srcset', originalSrc)
        img.setAttribute('src', originalSrc)
      }
    },
    [desktop, mobile, tablet, isSmall, isMedium, isLarge, isXLarge, isXXLarge]
  )

  if (!desktop?.url && !mobile?.url && !tablet?.url) {
    return null
  }

  const defaultImage = desktop?.placeholder || mobile?.placeholder || desktop?.url || mobile?.url
  const defaultOriginalImage = desktop?.url || mobile?.url
  const getAltText = (image?: IMedia): string => {
    if (image?.alternativeText && typeof image.alternativeText === 'string') {
      return image.alternativeText
    }
    return ''
  }

  const alt = getAltText(desktop) || getAltText(mobile) || getAltText(tablet) || 'Card image'

  return (
    <picture style={{ display: 'contents' }}>
      <MobileImages image={mobile} />
      <DesktopImages image={desktop} />
      <TabletImages image={tablet} />
      <source data-query="default" data-originalsrc={defaultOriginalImage} srcSet={defaultImage} />
      <img {...props} onLoad={handleOnLoad} loading="lazy" src={defaultImage} alt={alt} />
      {children}
    </picture>
  )
}

export default ResposiveImage
