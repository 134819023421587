/** @jsx jsx */
import { FC, useCallback, MouseEvent } from 'react'
import { Link } from 'gatsby'
// @ts-ignore
import { jsx, Box, Flex, Button } from 'theme-ui'
import urls from '~/common/urls'
import { Search } from '~/components/icons/search'
import VisuallyHidden from '@reach/visually-hidden'
import HeaderCartOpener from '~/components/header/header-cart-opener'
import { useCustomerContext } from '~/context/customer-context'
import { AccountButton } from './styles'
import { RiUserFill, RiUserLine } from '@remixicon/react'

const BespokeAccount: FC = () => {
  const { customer, isLoggedIn } = useCustomerContext()

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <AccountButton
        sx={{
          textTransform: 'uppercase',
          fontSize: '16px !important',
          display: 'block',
          maxWidth: ['100%', null, '240px', null, '100%'],
        }}
        to={isLoggedIn ? urls.account.accountDashboard : urls.account.signIn}
        data-cy="account-link"
      >
        <span className="bespoke-account-name">
          {isLoggedIn && customer?.firstName !== undefined ? `${customer?.firstName}` : 'Account'}
        </span>
        <span className="bespoke-account-icon">{isLoggedIn ? <RiUserFill /> : <RiUserLine />}</span>
      </AccountButton>
    </Flex>
  )
}

export default BespokeAccount
