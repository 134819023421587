/** @jsx jsx **/
// @ts-ignore
import { jsx, Box } from 'theme-ui'
import { useEffect, FC, useRef, useState, ReactNode, Fragment, RefObject } from 'react'
import { space } from '~/gatsby-plugin-theme-ui'
import LoadingForm from '~/components/loading-form'

let globalId = 0

export type OnFormReadyType = (val: any, formRef: RefObject<HTMLDivElement>) => void

type Props = {
  formId: string
  onSubmit?(val: any): void
  onFormSubmitted?: (f: object, cb?: () => any) => void
  onReady?: OnFormReadyType
  loading?: string | ReactNode
  productName?: string
}

const isFunction = (test: any) => typeof test === 'function'

const HubspotForm: FC<Props> = ({
  formId,
  onSubmit,
  onReady,
  loading,
  onFormSubmitted,
  productName,
}) => {
  const formRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState({
    loaded: false,
  })

  // todo - move env variables out of the component
  const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID
    ? parseInt(process.env.GATSBY_HUBSPOT_PORTAL_ID)
    : undefined

  const createForm = () => {
    // @ts-ignore
    if (window.hbspt) {
      // protect against component unmounting before window.hbspt is available
      if (!formRef.current) {
        return
      }
      const options = {
        portalId: portalId,
        formId: formId,
        target: `#${formRef.current.id}`,
        css: '',
        onFormSubmit: ($form: any) => {
          // ref: https://developers.hubspot.com/docs/methods/forms/advanced_form_options

          const formData = $form.serializeArray()
          if (isFunction(onSubmit)) {
            // @ts-ignore
            onSubmit(formData)
          }
        },
      }

      // @ts-ignore
      window.hbspt.forms.create(options)

      if (isFunction(onFormSubmitted)) {
        window.addEventListener('message', event => {
          if (
            event.data.type === 'hsFormCallback' &&
            formId === event.data.id &&
            event.data.eventName === 'onFormSubmitted'
          ) {
            // @ts-ignore
            onFormSubmitted({
              data: event.data,
            })
          }
        })
      }

      if (isFunction(onSubmit)) {
        window.addEventListener('message', event => {
          if (
            event.data.type === 'hsFormCallback' &&
            formId === event.data.id &&
            event.data.eventName === 'onFormSubmit'
          ) {
            // @ts-ignore
            onSubmit({
              data: event.data,
            })
          }
        })
      }

      return true
    } else {
      setTimeout(createForm, 1)
    }
  }
  const findFormElement = () => {
    // protect against component unmounting before form is added
    if (!formRef.current) {
      return
    }
    const form = formRef.current.querySelector(`iframe`)
    if (form) {
      setState({ loaded: true })

      if (onReady) {
        onReady(form, formRef)
      }
    } else {
      setTimeout(findFormElement, 1)
    }
  }
  const loadScript = () => {
    const script = document.createElement(`script`)
    script.defer = true
    script.onload = () => {
      createForm()
      findFormElement()
    }
    script.src = `//js.hsforms.net/forms/v2.js`
    document.head.appendChild(script)
  }
  useEffect(() => {
    globalId++
    // @ts-ignore
    if (!window.hbspt) {
      loadScript()
    } else {
      createForm()
      findFormElement()
    }
  }, [])
  if (state.loaded && productName) {
    const input = document.querySelector('input[name="product_name"]')

    input.value = productName
    input?.dispatchEvent(new Event('input', { bubbles: true }))
  }

  return (
    <div>
      <Box
        ref={formRef}
        id={`reactHubspotForm${formId}`}
        style={{ display: state.loaded ? 'block' : 'none' }}
        sx={{
          h2: {
            variant: 'text.title6',
            my: space.md1,
            fontFamily: 'var(--font-family-serif)',
            fontWeight: 'heading',
            lineHeight: 'heading',
          },
          h3: {
            variant: 'text.title6',
            my: space.md1,
            fontFamily: 'var(--font-family-serif)',
            fontWeight: 'heading',
            lineHeight: 'heading',
          },
          '.hs-custom-style .hs-input, .hs-custom-style fieldset': {
            display: 'grid',
            columnGap: space.gridGap3,
          },
          '.hs-custom-style fieldset .input': {
            marginRight: '0 !important',
          },
          '.form-columns-2': {
            gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
          },
          '.form-columns-3': {
            gridTemplateColumns: ['1fr', null, null, '1fr 1fr 1fr'],
          },
          '.hs-form-field': {
            width: '100% !important',
            mb: 28,
            '> label': {
              mb: space.sm1,
              fontSize: '16px',
              textTransform: 'uppercase',
            },
          },
          label: {
            variant: 'forms.label',
            display: 'block',
            mb: space.sm,
            fontFamily: 'var(--font-family-sans-serif)',
            '&.hs-error-msg': {
              color: 'error',
              fontSize: '16px',
            },
          },
          '.hs-input:not([type="checkbox"])': {
            variant: 'forms.input',
            width: '100%',
            appearance: 'none',
            fontFamily:
              'Helvetica,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
            '&.invalid.error': {
              variant: 'forms.inputError',
            },
          },
          '.hs-fieldtype-checkbox': {
            ul: {
              listStyle: 'none',
              m: 0,
              p: 0,
              li: {
                mb: 'md',
              },
            },
          },
          '.hs-form-checkbox': {
            label: {
              display: 'flex',
              position: 'relative',
              input: {
                opacity: 0,
                position: 'absolute',
                '+ span': {
                  ':before': {
                    content: '""',
                    variant: 'variants.inputRadioCheckbox',
                    display: 'inline-block',
                    position: 'relative',
                    top: 5,
                  },
                },
                '&:checked + span:after': {
                  content: '""',
                  bg: 'black',
                  left: 2,
                  top: 13,
                  variant: 'variants.inputRadioCheckboxInside',
                },
              },
            },
          },
          'input[type="file"].hs-input': {
            pt: '1px',
          },
          'textarea.hs-input': {
            height: 'auto',
            minHeight: 200,
            p: '12px 15px',
            lineHeight: 'body',
            resize: 'none',
          },
          'select.hs-input': {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentcolor' class='css-1swud1q'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100%',
          },
          '.hs-error-msgs': {
            variant: 'variants.list.none',
            mt: space.md,
            mr: space.sm,
            li: {
              position: 'relative',
              pl: space.md1,
              mb: space.xxs,
              label: {
                color: 'error',
              },
              '&:before': {
                content: '""',
                position: 'absolute',
                display: 'block',
                width: 16,
                height: 16,
                left: 0,
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16C12.3765 16 16 12.3765 16 8C16 3.63137 12.3686 0 7.99216 0C3.62353 0 0 3.63137 0 8C0 12.3765 3.63137 16 8 16ZM8 14.6667C4.29804 14.6667 1.34118 11.702 1.34118 8C1.34118 4.30588 4.2902 1.33333 7.99216 1.33333C11.6941 1.33333 14.6588 4.30588 14.6667 8C14.6745 11.702 11.702 14.6667 8 14.6667ZM7.99216 9.41961C8.36863 9.41961 8.58824 9.20784 8.59608 8.8L8.70588 4.65882C8.71373 4.25882 8.4 3.96078 7.98431 3.96078C7.56078 3.96078 7.26275 4.25098 7.27059 4.65098L7.37255 8.8C7.38039 9.2 7.6 9.41961 7.99216 9.41961ZM7.99216 11.9686C8.44706 11.9686 8.83922 11.6078 8.83922 11.1529C8.83922 10.6902 8.4549 10.3373 7.99216 10.3373C7.52941 10.3373 7.1451 10.698 7.1451 11.1529C7.1451 11.6 7.53726 11.9686 7.99216 11.9686Z' fill='%23F05C5C'/%3E%3C/svg%3E%0A")`,
              },
            },
          },
          '.hs-button': {
            appearance: 'none',
            color: 'button.text',
            fontSize: '18px',
            height: 64,
            textAlign: 'center',
            width: '100%',
            fontFamily: 'body',
            borderWidth: '1px',
            borderStyle: 'solid',
            cursor: 'pointer',
            px: 15,
            svg: {
              ml: '16px',
            },
            borderRadius: 0,
            variant: 'buttons.primary',
          },
          '.hs-submit': {
            mt: space.l,
            position: 'relative',
            // '&:after': {
            //   content: '""',
            //   position: 'absolute',
            //   display: 'block',
            //   width: 16,
            //   height: 16,
            //   right: '15px',
            //   top: '50%',
            //   transform: 'translateY(-50%)',
            //   backgroundRepeat: 'no-repeat',
            //   backgroundSize: 'contain',
            //   backgroundPosition: 'center',
            //   backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 6.00355C13.5 5.78369 13.4107 5.57092 13.2528 5.41489L8.74089 0.755319C8.56234 0.578014 8.37691 0.5 8.18463 0.5C7.74511 0.5 7.42921 0.819149 7.42921 1.25177C7.42921 1.47872 7.51849 1.67021 7.65584 1.81206L9.201 3.42908L11.1926 5.30851L9.59931 5.20922H1.27602C0.815901 5.20922 0.5 5.53546 0.5 6.00355C0.5 6.46454 0.815901 6.79078 1.27602 6.79078H9.59931L11.1926 6.69149L9.201 8.57092L7.65584 10.1879C7.51849 10.3298 7.42921 10.5213 7.42921 10.7482C7.42921 11.1809 7.74511 11.5 8.18463 11.5C8.37691 11.5 8.56234 11.422 8.72715 11.2589L13.2528 6.58511C13.4107 6.42908 13.5 6.21631 13.5 6.00355Z' fill='white'/%3E%3C/svg%3E%0A")`,
            // },
          },
        }}
      />
      {!state.loaded && <Fragment>{loading || <LoadingForm />}</Fragment>}
    </div>
  )
}

export { HubspotForm }
