/** @jsx jsx **/
import { Box, jsx } from 'theme-ui'
import Dialog from '~/components/dialog'
import HeadingV2 from '~/componentsV2/heading'
import theme from '~/gatsby-plugin-theme-ui'

type Props = {
  showDialog: boolean
  title?: string
  children: React.ReactNode
  close: () => void
  showPhone?: boolean
}

const CustomQuickShipFormDialog = ({
  showDialog,
  close,
  children,
  showPhone = true,
  title = 'REQUEST INFORMATION',
}: Props) => {
  return (
    <Dialog showDialog={showDialog} close={close}>
      <Box
        sx={{
          textAlign: 'center',
          p: theme.space.md2,
          pb: theme.space.l2,
          h2: {
            my: theme.space.md1,
            fontFamily: 'var(--font-family-serif) !important',
            fontWeight: 'heading',
            lineHeight: 'heading',
          },
        }}
      >
        <HeadingV2 level="2" sx={{ textAlign: 'left' }}>
          {title}
          {showPhone && (
            <div style={{ display: 'flex' }}>
              <a href="tel:8882164277" style={{ textDecoration: 'none' }}>
                <HeadingV2 level="3" sx={{ fontFamily: 'Helvetica' }}>
                  PHONE: 888-216-4277
                </HeadingV2>
              </a>
            </div>
          )}
        </HeadingV2>
        <Box
          sx={{
            label: {
              textAlign: 'left',
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Dialog>
  )
}

export default CustomQuickShipFormDialog
