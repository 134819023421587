/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { useMemo } from 'react'
import { jsx, Box, Flex, Container, ThemeUIStyleObject } from 'theme-ui'
import { Link } from 'gatsby'
// @ts-ignore
import { useLocation } from '@reach/router'
import { Logo } from '~/components/logo'
import urls from '~/common/urls'
import RightMenuDesktop from '~/components/header/right-menu/desktop'
import useHeaderScrolling from '~/hooks/use-header-scolling'
import { HeaderVariantType } from '~/@types/models'
import HeaderWrapper from '~/components/header/header-wrapper'
import Search from '~/components/search'
import MenuDesktop from '~/components/menu-desktop'
import { useSearchHandlers } from '~/components/header/use-search-handlers'
import { useCustomerContext } from '~/context/customer-context'
import { HeaderContextProvider } from '~/components/header/header-context'

import { headerHeight } from '~/gatsby-plugin-theme-ui'

interface Props {
  headerVariant: HeaderVariantType
  stickyMenu: boolean
  sxProps?: ThemeUIStyleObject
}

const isSearchPage = (pathname: string) => String(pathname).startsWith(urls.pages.search)

const HeaderDesktop = ({ headerVariant, sxProps, stickyMenu = false }: Props) => {
  const { isScrolling } = useHeaderScrolling()
  const { showSearch, toggleSearch } = useSearchHandlers()
  const { pathname } = useLocation()
  const isDarkSolid = headerVariant === 'dark_solid'

  const styleSettings = useMemo(() => {
    return {
      container: {
        marginBottom: isScrolling ? 12 : isDarkSolid ? 35 : 24,
        paddingTop: isScrolling ? 11 : isDarkSolid ? 60 : 35,
      },
      logo: {
        width: isScrolling ? 300 : isDarkSolid ? 375 : 350,
        color: isDarkSolid ? 'main' : 'initial',
      },
      rightMenu: {
        color: isDarkSolid && 'main',
      },
    }
  }, [headerVariant, isScrolling])

  return (
    <HeaderWrapper
      headerVariant={headerVariant}
      display="DESKTOP"
      sxProps={{
        height: isScrolling ? 90 : headerHeight[3],
        display: ['none', null, null, 'block'],
        ...sxProps,
      }}
    >
      <Container
        className="header-desktop"
        id={stickyMenu ? 'sticky-menu' : 'menu'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          alignItems: 'center',
          mb: styleSettings.container.marginBottom,
          pt: styleSettings.container.paddingTop,
          transition: 'all .5s',
          'a, button': {
            fontSize: '18px',
            color: 'inherit',
            textDecoration: 'none',
          },
        }}
      >
        <Box>
          <Link
            sx={{
              textTransform: 'uppercase',
              fontSize: '16px !important',
            }}
            to={urls.collections.newArrivals}
          >
            New Launches
          </Link>
        </Box>
        <Box>
          <Logo width={styleSettings.logo.width} color={styleSettings.logo.color} />
        </Box>
        <Flex
          sx={{
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
          }}
        >
          <RightMenuDesktop
            disableSearchOpen={isSearchPage(pathname)}
            toggleSearch={toggleSearch}
            color={styleSettings.rightMenu.color}
          />
        </Flex>
      </Container>
      <HeaderContextProvider variant={headerVariant}>
        <MenuDesktop isScrolling={isScrolling} searchActive={showSearch} />
      </HeaderContextProvider>
      {showSearch && <Search showSearch={showSearch} toggleSearch={toggleSearch} />}
    </HeaderWrapper>
  )
}

export default HeaderDesktop
